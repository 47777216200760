import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"
import HeroBg from "../images/icons/hero-11-bg.svg"

const TermsOfServicesPage = () => {
  return (
    <Layout headerBg="bg-beige">
      <Seo title="Terms of Services" />
      <section className="bg-beige pt-20 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex -mx-4">
            <div className="w-full md:/w-1/2 lg:w-4/6 px-4">
              <div className="relative z-10 pb-72">
                <DiagonalArrowIcon
                  className="stroke-primary mr-2"
                  width="42px"
                  height="42px"
                />
                <h1 className="text-h0/m md:text-h0 mt-2 mb-2">
                  Terms of Services
                </h1>
              </div>
            </div>
            <div className="w-full md:/w-1/2 lg:w-2/6 px-4 hidden md:block">
              <div className="relative h-full">
                <div className="absolute top-0 left-0 h-full">
                  <HeroBg className="ml-14" width="451px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 relative">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="prose-entry">
            <h3>1. Terms</h3>
            <p>
              By accessing the website at{" "}
              <a href="https://www.peak11.com">https://www.peak11.com</a>{" "}
              (PeakMedia's Website), you are agreeing to be bound by these terms
              of service, all applicable laws and regulations, and agree that
              you are responsible for compliance with any applicable local law.
              If you do not agree with any of these terms, you are prohibited
              from using or accessing this site. The materials contained in this
              website are protected by applicable copyright and trademark law.
            </p>
            <h3>2. Use License</h3>
            <p>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on PeakMedia's Website for
              personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
            </p>
            <ul>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on PeakMedia's website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ul>
            <p>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by PeakMedia at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>
            <h3>3. Disclaimer</h3>
            <p>
              The materials on PeakMedia's Website are provided on an 'as is'
              basis. PeakMedia makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights. Further, PeakMedia does not warrant or make any
              representations concerning the accuracy, likely results, or
              reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to
              this site.
            </p>
            <h3>4. Limitations</h3>
            <p>
              In no event shall PeakMedia or its suppliers be liable for any
              damages (including, without limitation, damages for loss of data
              or profit, or due to business interruption) arising out of the use
              or inability to use the materials on PeakMedia's Website, even if
              PeakMedia or a PeakMedia authorized representative has been
              notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied
              warranties, or limitations of liability for consequential or
              incidental damages, these limitations may not apply to you.
            </p>
            <h3>5. Accuracy of Materials</h3>
            <p>
              The materials appearing on PeakMedia's Website could include
              technical, typographical, or photographic errors. PeakMedia does
              not warrant that any of the materials on its website are accurate,
              complete or current. PeakMedia may make changes to the materials
              contained on its website at any time without notice. However
              PeakMedia does not make any commitment to update the materials.
            </p>
            <h3>6. Links</h3>
            <p>
              PeakMedia has not reviewed all of the sites linked to its website
              and is not responsible for the contents of any such linked site.
              The inclusion of any link does not imply endorsement by PeakMedia
              of the site. Use of any such linked website is at the user's own
              risk.
            </p>
            <h3>7. Site Terms of Use Modifications</h3>
            <p>
              PeakMedia may revise these terms of service for its website at any
              time without notice. By using this website you are agreeing to be
              bound by the then current version of these terms of service.
            </p>
            <h3>8. Governing Law</h3>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of the Republic of Poland and you
              irrevocably submit to the exclusive jurisdiction of the courts in
              that location.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsOfServicesPage
